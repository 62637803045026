import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"

import Bio from "../components/bio"
import Title from "../components/title"
import Layout from "../components/layout"
import TagsWrapper from "../components/tags"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import Navigation from "../components/nav"

class BlogPostTemplate extends React.Component {

  componentDidMount() {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CSSRulePlugin);

    var tl = gsap.timeline();

    tl.add(gsap.fromTo("nav, .blog-title", { opacity: 0}, {duration: 1, opacity: 1}));
    tl.add(gsap.fromTo(".blog-date", {opacity: 0}, {duration: 0.3, x: 0, opacity: 1}));
    

    ScrollTrigger.create({
        trigger: document.querySelector('.blog-body'),
        toggleClass: 'active',
        // this toggles the class again when you scroll back up:
        toggleActions: 'play none none none',
        // this removes the class when the scrolltrigger is passed:
        // once: true,
      });
  }

  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <div className="container-fluid justify-content-center d-flex">
            <div className="blog-container col-12 col-md-6 text-center">
                <h1 className="blog-title">{post.frontmatter.title.trim()}<span className="color-primary">.</span></h1>
                <p className="blog-date">{post.frontmatter.date}</p>

                <TagsWrapper tags={post.frontmatter.tags.split(',')}></TagsWrapper>

                <div className="blog-body">
                    <MDXRenderer>{post.body}</MDXRenderer>
                </div>
                

                <hr />
                <Bio />

                <ul
                style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                }}
                >
                <li>
                    {previous && (
                    <a className="body-link" href={`/blog${previous.fields.slug}`} rel="prev">
                        ← {previous.frontmatter.title}
                    </a>
                    )}
                </li>
                <li>
                    {next && (
                    <a className="body-link" href={`/blog${next.fields.slug}`} rel="next">
                        {next.frontmatter.title} →
                    </a>
                    )}
                </li>
                </ul>
            </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        category
      }
    }
  }
`
